import React from "react";
// import { GatsbyImage } from "gatsby-plugin-image"
// import { graphql } from "gatsby"
import Layout from "../components/Layout";
import photo from "../images/nutri.webp";
import HeaderPhoto from "../components/HeaderPhoto";
import * as styles from "../styles/nutrimeal.module.css";

// export default function Nutrimeal({data}) {
export default function Nutrimeal() {
  const content = (
    <div className={styles.text}>
      <p>
        Novo u našem gradu! Za sve ljubitelje obroka po mojim preporukama i
        recepturama!
      </p>
      <p>Trg Ustanka 23, Čačak</p>
      <p>0656386405</p>
    </div>
  );
  return (
    <Layout>
      <HeaderPhoto title="NUTRImeal" photo={photo} node={content} />
      <div className={styles.info}>
        <p>
          Dobrodošli u <strong>NutriMeal</strong>, gde zdravlje i ukus idu ruku
          pod ruku! Naša misija je da vam pružimo obroke koji su ne samo ukusni,
          ali i nutritivno bogati, osmišljeni da unaprede vaše zdravlje i
          dobrobit. Svaki obrok na našem meniju kreiran je od strane stručnjaka
          za ishranu, Sonje Nikačević, sa posebnim fokusom na balansirane,
          zdrave sastojke.
        </p>
        <h2>Zašto Odabrati NutriMeal?</h2>
        <p>
          <strong>Zdravi i nutritivni obroci:</strong> Svi naši obroci su
          pažljivo osmišljeni da budu bogati hranljivim materijama, bez
          kompromisa kad je ukus u pitanju.
        </p>
        <p>
          <strong>Stručno savetovanje:</strong> Naš meni je kreiran od strane
          renomirane nutricionistkinje, Sonje Nikačević, garantujući obroke koji
          su ne samo ukusni, već i optimalno balansirani za vaše zdravlje.
        </p>
        <p>
          <strong>Prilagođavanje svih sotreba:</strong> Bilo da ste na posebnom
          režimu ishrane ili imate specifične dijetetske zahteve, naš meni nudi
          raznovrsnost koja zadovoljava sve potrebe.
        </p>
        <h2>Naš Meni</h2>
        <p>
          Izaberite iz široke palete ukusnih, zdravih obroka - od svežih salata,
          preko nutritivno bogatih glavnih jela, do laganih i zdravih deserta.
          Svaki obrok je pažljivo pripremljen sa najkvalitetnijim sastojcima,
          osiguravajući da svaki zalogaj bude korak ka boljem zdravlju.
        </p>
        <h2>Posetite Nas</h2>
        <p>
          NutriMeal nije samo mesto za obrok, već destinacija za one koji cene
          zdrav način života. Posetite nas i doživite razliku koju pravi
          pravilna ishrana. Očekujemo vas!
        </p>
      </div>
      {/* <div className={styles.imageHolder}>
        {data.allFile.edges.map(edge => {
          return <GatsbyImage key={edge.node.id} image={edge.node.childImageSharp.gatsbyImageData} alt="" />;
        })}
      </div> */}
    </Layout>
  );
}

// export const indexQuery = graphql`
//   query AssetsPhotos {
//     allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "photos"}}) {
//       edges {
//         node {
//           id
//           childImageSharp {
//             gatsbyImageData(
//               layout: FIXED
//               height: 300
//             )
//           }
//         }
//       }
//     }
//   }
// `

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
